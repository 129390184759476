import React from 'react';
import { FaTwitter, FaDiscord } from "react-icons/fa"
const TwitterLink = () => {
    const twitterUsername = 'Anime_zkGirls';
    const twitterUrl = `https://twitter.com/${twitterUsername}`;

    return (
        <div>
            <a href={twitterUrl} target="_blank" rel="noopener noreferrer">
                <FaTwitter size="1.5em" color='lightblue'/> {/* アイコンのサイズを変更したい場合は、sizeプロパティを調整してください */}
            </a>
        </div>
    );
};
const DiscordLink = () => {
    const discordInviteLink = 'https://discord.gg/tDrshThHBV';

    return (
      <div>
        <a href={discordInviteLink} target="_blank" rel="noopener noreferrer">
          <FaDiscord size="1.5em" color='purple'/> {/* アイコンのサイズを変更したい場合は、sizeプロパティを調整してください */}
        </a>
      </div>
    );
}
export { TwitterLink, DiscordLink };